<template>
  <div class="icon-container" :style="boxStyle">
    <svg class="iconFont-svg" :style="style" aria-hidden="true">
      <use :xlink:href="'#' + icon" />
    </svg>
  </div>
</template>

<script>
import { reactive, getCurrentInstance, onMounted } from "vue";
import "../sheet/iconFont/iconfont.css";
import "../sheet/iconFont/iconfont.js";

export default {
  name: "IconFontSymbol",
  props: ["boxStyle", "style", "icon"],
};
</script>
